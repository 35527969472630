<template>
  <div class="deck-text-field-append-default">
    <transition name="slide-x-reverse-transition">
      <div
        v-show="hovering || fixedControls"
        class="deck-text-field-append-default__controls"
      >
        <transition name="slide-x-reverse-transition">
          <deck-button
            v-show="canClear && !hideClear && hovering"
            :text="$t('global.clear')"
            size="small"
            kind="ghost"
            icon="xmark"
            color="controls"
            class="deck-text-field__clear-button"
            is-ready
            @click.stop="$emit('clear')"
          />
        </transition>
        <template v-if="hasControls">
          <deck-button
            v-for="control, index in controls"
            :key="index"
            v-bind="{ ...control, ...generateControlEvents(control) }"
            :size="control.size || 'small'"
            :kind="control.kind || 'ghost'"
            :color="control.color || 'controls'"
            is-ready
          >
            <template
              v-if="$slots[`control:${control.controlName}:tooltip`]"
              #tooltip
            >
              <slot :name="`control:${control.controlName}:tooltip`" />
            </template>
          </deck-button>
        </template>
      </div>
    </transition>
    <div
      v-if="hasTags"
      class="deck-text-field-append-default__tags"
    >
      <deck-chip
        v-for="tag, index in tags"
        v-bind="tag"
        :key="index"
        :size="tag.size || 'x-small'"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeckTextFieldAppendDefault',
  components: {
    DeckButton: defineAsyncComponent(() => import('~/deck/button')),
    DeckChip: defineAsyncComponent(() => import('~/deck/chip')),
  },
  props: {
    /**
     * Tags defined from parent
     * @type {Array}
     * @default undefined
     */
    tags: {
      type: [Array],
      default: undefined,
    },

    /**
     * Controls defined from parent
     * @default undefined
     * @type {Array}
     */
    controls: {
      type: [Array],
      default: undefined,
    },

    /**
     * Whether the component is being hovered, communicated from the parent.
     * @type {boolean}
     * @default false
     */
    hovering: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the component can be cleared, communicated from the parent.
     * @type {boolean}
     * @default false
     */
    canClear: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the clear button should be hidden, communicated from the parent.
     * @type {boolean}
     * @default false
     */
    hideClear: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the component has fixed controls, communicated from the parent.
     * @type {boolean}
     * @default false
     */
    fixedControls: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'clear',
    'control:click',
    'control:hover',
  ],
  computed: {
    hasTags() {
      return this.tags?.length > 0;
    },

    hasControls() {
      return this.controls?.length > 0;
    },
  },
  methods: {
    generateControlEvents(control) {
      const events = {};

      events.onClick = event => this.handleControlClick(control, event);

      if (control.controlName) {
        events.onMouseenter = () => this.handleControlHover(control, true);
        events.onMouseleave = () => this.handleControlHover(control, false);
      }

      return events;
    },
    handleControlClick(control, event) {
      if (control.controlName) {
        /**
         * Custom named event from `controls` prop to be emitted when a control is clicked.
         * @event control:click"
         */
        this.$emit('control:click', control.controlName, event);
      }

      if (typeof control.action === 'function') {
        control.action();
      }
    },
    handleControlHover(control, isHovered) {
    /**
     * Custom named event from `controls` prop to be emitted when a control is hovered.
     * @event control:hover"
     */
      this.$emit('control:hover', control.controlName, isHovered);
    },

  },
};
</script>

<style lang="scss">
.deck-text-field-append-default {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.deck-text-field-append-default__controls {
  display: flex;
  align-items: center;
  gap: 1px;
}

.deck-text-field-append-default__tags {
  display: flex;
  align-items: center;
  gap: var(--z-s1);
  margin-left: var(--z-s1);
}
</style>
